import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { HomeComponent } from './mayna/home/home.component';
import { MaynaContactComponent } from './mayna/contact/mayna-contact.component';
import { NewsIndexComponent } from './mayna/news/index/news-index.component';
import { NewsDetailComponent } from './mayna/news/detail/news-detail.component';
import { MnServicesComponent } from './mayna/mn-services/mn-services.component';
import { AboutComponent } from './mayna/about/about.component';
import { CustomerIndexComponent } from './mayna/customer/index/customer-index.component';
import { CustomerDetailComponent } from './mayna/customer/detail/customer-detail.component';
import { MnFeedbackDetailComponent } from './mayna/mn-feedback-detail/mn-feedback-detail.component';
export const routes: Routes = [
    {
        path: '',
        component: DefaultLayoutComponent,
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'contact',
                component: MaynaContactComponent
            },
            {
                path: 'blog',
                component: NewsIndexComponent
            },
            {
                path: 'blog/:id',
                component: NewsDetailComponent
            },
            {
                path: 'services',
                component: MnServicesComponent,
            },
            {
                path: 'about',
                component: AboutComponent,
            },
            {
                path: 'customers',
                component: CustomerIndexComponent
            },
            {
                path: 'customers/:id',
                component: CustomerDetailComponent
            },
            {
                path: 'feedback/:id',
                component: MnFeedbackDetailComponent
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'error/404'
    }
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy });
