import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class CategoriesService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/categories`);
    }
    searchTree(idCha = 0, trangThai: number = -1, includeParent: boolean = true,) {
        const queryString = `${this.serviceUri}/SearchTree?idCha=${idCha}&trangThai=${trangThai}&includeParent=${includeParent}`;
        return this.defaultGet(queryString);
    }

    getTree(idCha: number, useCache: boolean = true, includeParent: boolean = true) {
        const queryString = `${this.serviceUri}/GetTree?idCha=${idCha}&useCache=${useCache}&includeParent=${includeParent}`;
        return this.defaultGet(queryString);
    }
}
