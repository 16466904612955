<nav class="Page navigation example">
    <ul class="pagination">
        <li class="page-item" *ngIf="pageIndex >=2">
            <a class="page-link prev" (click)="onPrev()"><i class="fa fa-angle-left"></i>Trang trước</a>
        </li>

        <li class="page-item" *ngFor="let pageNum of getPages()">
            <a class="page-link" (click)="onPage(pageNum)">{{pageNum}}</a>
        </li>

        <li class="page-item" *ngIf="lastPage()===false">
            <a class="page-link next" (click)="onNext()">Trang sau <i class="fa fa-angle-right"></i></a>
        </li>
    </ul>
</nav>