import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { News } from '../../models/news';
import { CategoriesService } from '../../services/categories.service';
import { ComponentBase } from '../../services/component-base';
import { FeedbackService } from '../../services/feedback.service';
import { Feedback } from '../../models/feedback';

@Component({
    selector: 'app-mn-feedback-detail',
    templateUrl: './mn-feedback-detail.component.html',
    styleUrls: ['./mn-feedback-detail.component.scss']
})
export class MnFeedbackDetailComponent extends ComponentBase implements OnInit {

    id = 0;
    itemDetail: Feedback = new Feedback();
    dsFeedback = [];
    query = '';

    constructor(
        protected _activatedRoute: ActivatedRoute,
        protected _categoriesService: CategoriesService,
        private _feedbackService: FeedbackService,
        protected _router: Router
    ) {
        super();
    }

    async ngOnInit() {
        await this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.id = pid;
            });

        this.getDetail();
        this.getData();
    }

    getDetail() {
        this._feedbackService.getDetail(this.id).then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
            }
        });
    }

    getData() {
        console.log(this.id);
        var searchModel = {
            keyword: '',
            trangThai: 1,
            pageIndex: 1,
            pageSize: 11,
            orderCol: 'Id',
            isDesc: true,
        };
        this._feedbackService.getsBySearch(searchModel)
            .then(response => {
                this.dsFeedback = response.data.filter(x => (x.id - 0) !== (this.id - 0));
            });
    }

    onSearchBlog() {
        this._router.navigateByUrl(`/blog?query=${this.query}`);
    }
}
