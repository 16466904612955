<header [class.header-bg]="isAddHeaderBg">
    <div class="container">
        <div class="header-content">
            <div class="logo">
                <a routerLink="/">
                    <img src="assets/images/logo.jpg" alt="">
                </a>
            </div>
            <!--logo end-->
            <nav>
                <ul>
                    <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Vườn
                            Mayna</a></li>
                    <li><a routerLink="/about" class="nav-link" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Sự tích khu vườn</a>
                    </li>
                    <li><a routerLink="/services" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Quy trình làm vườn</a>
                    </li>
                    <li>
                        <a routerLink="/customers" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Hoa trái của vườn</a>
                    </li>
                    <li>
                        <a routerLink="/blog" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Nhật
                            ký trồng vườn</a>
                    </li>
                    <!-- <li><a routerLink="/contact" title="">Đường thăm vườn</a></li> -->
                </ul>
            </nav>
            <div class="contact-head-info">
                <!-- <h4>098 785 11 11</h4> -->
                <a href="tel:0987851111" title="">098 785 11 11</a>
            </div>
            <!--navigation end-->

            <!--contact-head-info end-->
            <div class="menu-btnn">
                <div class="menu-btn" [class.active]="isShowMobileMenu" (click)="showMobileMenu()">
                    <span class="bar1"></span>
                    <span class="bar2"></span>
                    <span class="bar3"></span>
                </div>
                <!--menu-bar end-->
            </div>
        </div>
        <!--header-content end-->
    </div>
</header>
<!--header end-->

<div class="responsive-mobile-menu" [class.active]="isShowMobileMenu">
    <ul>
        <li><a class="active" routerLink="/" title="">Vườn Mayna</a></li>
        <li><a routerLink="/about" title="">Sự tích khu vườn</a></li>
        <li><a routerLink="/services" title="">Quy trình làm vườn</a></li>
        <li>
            <a routerLink="/customers" title="">Hoa trái của vườn</a>
        </li>
        <li>
            <a routerLink="/blog" title="">Nhật ký trồng vườn</a>
        </li>
        <!-- <li><a routerLink="/contact" title="">Đường thăm vườn</a></li> -->
    </ul>
</div>
<!--responsive-mobile-menu end-->