import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { CategoriesService } from '../../../services/categories.service';
import { NewsService } from '../../../services/news.service';
import { SecondPageIndexBase } from '../../../services/second-page-index-base';

@Component({
    selector: 'app-news-index',
    templateUrl: './news-index.component.html',
    styleUrls: ['./news-index.component.scss']
})
export class NewsIndexComponent extends SecondPageIndexBase implements OnInit {

    idCategory = 0;
    itemDetail: any = { ten: 'Thông báo' };
    dsCategory = [];
    constructor(
        protected _newsService: NewsService,
        protected _injector: Injector,
        protected _categoriesService: CategoriesService,
        private _route: ActivatedRoute,
    ) {
        super(_newsService, _injector);
    }

    async ngOnInit() {
        this.pageSize = 6;
        this.getCategories();
        this._route.queryParams.subscribe(params => {
            this.idCategory = params['catid'];
            this.query = params['query'];
            this.getData();
        });

        //this.getDetail();
    }

    async getData() {
        this.dataSource = [];
        this.totalRecord = 0;
        var searchModel = {
            keyword: this.query,
            idCategory: this.idCategory,
            trangThai: 1,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol ? this.orderCol : 'Id',
            isDesc: true,
        };
        await this._newsService.find(searchModel)
            .then(response => {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
            }, error => {
                this.isLoading = false;

            });
    }

    onGoPage($event) {
        this.pageIndex = $event;
        this.getData();
    }

    getCategories() {
        this._categoriesService.getTree(4, true, false).then(rs => {
            if (rs.success) {
                this.dsCategory = rs.data;
            }
        })
    }

    getDetail() {
        if (this.idCategory > 0) {
            this._categoriesService.getDetail(this.idCategory).then(rs => {
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            })
        }
    }
    goCategory(id: number) {
        this.idCategory = id;
        this.getData();
    }

}

