<section class="pager-section no-bg style2">
    <div class="container">
        <div class="pager-info">
            <ul class="breadcrumb">
                <li><a routerLink="/" title="">Trang chủ</a></li>
                <li><a routerLink="/blog" title="">Nhật ký trồng vườn</a></li>
            </ul>
        </div>
        <!--pger-imgs end-->
        <div class="clearfix"></div>
    </div>
</section>
<!--pager-section end-->

<section class="page-content pt-0" style="padding-bottom: 40px; margin-top: 30px">
    <div class="container">
        <div class="blog-single">
            <div class="row">
                <div class="col-lg-4">
                    <div class="sidebar">
                        <div class="widget widget-search">
                            <form>
                                <input type="text" name="search" placeholder="Tìm kiếm tin/bài" [(ngModel)]="query">
                                <button type="submit" (click)="onSearchBlog()"><i class="la la-search"></i></button>
                            </form>
                        </div>
                        <!--widget-search end-->
                        <div class="widget widget-tags bg-white">
                            <h3 class="widget-title">Chuyên mục</h3>
                            <ul class="tags-list">
                                <li *ngFor="let item of dsCategory">
                                    <a [routerLink]="['/blog']" [queryParams]="{catid: item.id}"
                                        title="">{{item.ten}}</a>
                                </li>
                            </ul>
                        </div>
                        <!--widget-tags end-->
                        <div class="widget popular-posts">
                            <h3 class="widget-title">Cùng chuyên mục</h3>
                            <ul>
                                <li *ngFor="let item of dsTinCungChuyenMuc">
                                    <div class="wid-post">
                                        <div class="wid-post-thumb">
                                            <img src="{{item.imageUrl}}" alt="">
                                        </div>
                                        <div class="wid-post-info">
                                            <h3><a routerLink="/blog/{{item.id}}" title="">{{item.title}}</a></h3>
                                            <span>{{itemDetail.modified | date:'dd/MM/yyyy HH:mm'}}</span>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <!--sidebar end-->
                </div>
                <div class="col-lg-8">
                    <div class="blog-post single">
                        <div class="blog-info">
                            <span>{{itemDetail.modified | date:'dd/MM/yyyy HH:mm'}}</span>
                            <h2 class="blog-title">{{itemDetail.title}}</h2>
                            <p class="blog-detail" [innerHTML]="itemDetail.contents | safe: 'html'"></p>
                            <div class="row" *ngIf="galleryImages.length > 0">
                                <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
                            </div>
                        </div>
                    </div>
                    <!--blog-post single end-->
                </div>
            </div>
        </div>
        <!--blog-single end-->
    </div>
</section>
<!--page-content end-->