import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
    isShowMobileMenu = false;
    isAddHeaderBg = false;
    menuText = '';
    constructor(
        private _router: Router
    ) {
        this._router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.showHeaderBg(event.url);
            }
        });
    }

    async ngOnInit() {
        this.isAddHeaderBg = false;
        this.showHeaderBg(this._router.url);
        this.isShowMobileMenu = false;
    }

    showMobileMenu() {
        this.isShowMobileMenu = !this.isShowMobileMenu;
    }

    showHeaderBg(url) {
        // console.log(url);
        if (url.startsWith('/blog/') || url.startsWith('/customers/') || url.startsWith('/feedback/')) {
            this.isAddHeaderBg = true;
        } else {
            this.isAddHeaderBg = false;
        }
        if (url.startsWith('/blog/')) {
            this.menuText = '/blog';
        }
        else if (url.startsWith('/customers/')) {
            this.menuText = '/customers';
        }
        else if (url.startsWith('/feedback/')) {
            this.menuText = '/feedback';
        } else {
            this.menuText = url;
        }
        // console.log(this.isAddHeaderBg);
    }

}
