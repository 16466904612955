<section class="pager-section">
    <div class="container">
        <div class="pager-info">
            <ul class="breadcrumb">
                <li><a routerLink="/" title="">Trang chủ</a></li>
                <li><span>Liên hệ</span></li>
            </ul>
            <!--breadcrumb end-->
            <h2>Liên hệ</h2>
            <span>Liên lạc với chúng tôi bằng cách nào</span>
        </div>
        <div class="pger-imgs style2">
            <div class="abt-imgz">
                <img src="assets/images/resources/about3.jpg" alt="">
            </div>
        </div>
        <!--pger-imgs end-->
        <div class="clearfix"></div>
    </div>
</section>
<!--pager-section end-->

<section class="page-content">
    <div class="container">
        <div class="contact-page">
            <div class="contact-head">
                <p>Bất kỳ câu hỏi hoặc đề xuất? <br /> Viết tin nhắn cho chúng tôi và chúng tôi sẽ liên hệ với bạn!</p>
            </div>
            <!--contact-head end-->
            <div class="contact-main">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="contact_info">
                            <h3 class="sub-title white">Liên hệ</h3>
                            <ul class="cl-list">
                                <!-- <li>
                                    <span class="ci-icon">
                                        <img src="assets/images/ci1.png" alt="">
                                    </span>
                                    <p>1556 Broadway, suite 416 New York, NY 10120 USA</p>
                                </li>
                                <li>
                                    <span class="ci-icon">
                                        <img src="assets/images/ci2.png" alt="">
                                    </span>
                                    <p><span>Mon-Sat:</span> 9 am til 6 pm <span>Sunday:</span> Closed</p>
                                </li> -->
                                <li>
                                    <span class="ci-icon">
                                        <img src="assets/images/ci3.png" alt="">
                                    </span>
                                    <p>mayna.space@gmail.com</p>
                                </li>
                                <li>
                                    <span class="ci-icon">
                                        <img src="assets/images/ci4.png" alt="">
                                    </span>
                                    <p>098 785 11 11</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="contact-main-form">
                            <form [formGroup]="formGroup" id="contact-form">
                                <div class="response"></div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Họ và tên</label>
                                            <input type="text" class="form-control name"
                                                [(ngModel)]="itemDetail.displayName" formControlName="displayName"
                                                placeholder="Đỗ Thành Trung">
                                        </div>
                                        <!--form-group end-->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Địa chỉ?</label>
                                            <input type="text" class="form-control name"
                                                [(ngModel)]="itemDetail.address" formControlName="address"
                                                placeholder="Hoài Đức, Hà Nội">
                                        </div>
                                        <!--form-group end-->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>E-mail</label>
                                            <input type="email" class="form-control email"
                                                [(ngModel)]="itemDetail.email" formControlName="email"
                                                placeholder="Example@gmail.com">
                                        </div>
                                        <!--form-group end-->
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label>Số điện thoại</label>
                                            <input type="text" name="name" class="form-control"
                                                [(ngModel)]="itemDetail.phoneNumber" formControlName="phoneNumber"
                                                placeholder="098 785 11 11">
                                        </div>
                                        <!--form-group end-->
                                    </div>
                                    <div class="col-sm-10">
                                        <div class="form-group">
                                            <label>Nội dung</label>
                                            <textarea class="form-control" [(ngModel)]="itemDetail.message"
                                                formControlName="message"
                                                placeholder="Xin chào MayNa! Tôi có một vài câu hỏi..."></textarea>
                                        </div>
                                        <!--form-group end-->
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-submit">
                                            <button type="button" (click)="addContact()"><img
                                                    src="assets/images/submit.png" alt=""></button>
                                        </div>
                                        <!--form-submit end-->
                                    </div>
                                </div>
                            </form>
                        </div>
                        <!--contact-main-form end-->
                    </div>
                </div>
            </div>
            <!--contact-main end-->
            <div class="contact-social">
                <span>Mạng xã hội :</span>
                <ul class="social-links without-bg">
                    <li><a href="https://www.facebook.com/maynaspaces" title="" target="_blank"><i
                                class="fab fa-facebook-f"></i></a>
                    </li>
                    <!-- <li><a href="#" title=""><i class="fab fa-instagram"></i></a></li> -->
                </ul>
            </div>
            <!--contact-social end-->
        </div>
        <!--contact-page end-->
    </div>
</section>
<!--page-content end-->