import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { News } from '../../../models/news';
import { CategoriesService } from '../../../services/categories.service';
import { ComponentBase } from '../../../services/component-base';
import { NewsService } from '../../../services/news.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';
import { NewsAttactmentService } from '../../../services/news-attactment.service';

@Component({
    selector: 'app-news-detail',
    templateUrl: './news-detail.component.html',
    styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent extends ComponentBase implements OnInit {

    id = 0;
    itemDetail: News = new News();
    dsTinCungChuyenMuc = [];
    dsTinKhac = [];
    dsCategory = [];
    query = '';
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];

    constructor(
        protected _newsService: NewsService,
        protected _activatedRoute: ActivatedRoute,
        protected _categoriesService: CategoriesService,
        protected _newsAttactmentService: NewsAttactmentService,
        protected _router: Router,
    ) {
        super();
        this.galleryOptions = [
            {
                width: '100%',
                height: '1200px',
                thumbnailsColumns: 6,
                imageAnimation: NgxGalleryAnimation.Slide
            },
            // max-width 800
            {
                width: '100%',
                height: '800px',
                breakpoint: 800,
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];
    }

    async ngOnInit() {
        this.getCategories();
        await this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.id = pid;
                this.getAttactment(pid);
                this.getDetail();
                this.getData();
            });
    }

    getDetail() {
        this._newsService.getDetail(this.id).then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
            }
        });
    }

    getData() {
        var searchModel = {
            keyword: '',
            idCategory: this.itemDetail.idCategory,
            trangThai: 1,
            pageIndex: 1,
            pageSize: 5,
            orderCol: 'Id',
            isDesc: true,
        };
        this._newsService.find(searchModel)
            .then(response => {
                this.dsTinCungChuyenMuc = response.data.filter(x => (x.id - 0) !== (this.id - 0));
            });
    }

    getCategories() {
        this._categoriesService.getTree(4, true, false).then(rs => {
            if (rs.success) {
                this.dsCategory = rs.data;
            }
        })
    }

    getAttactment(id: number) {
        this._newsAttactmentService.getsByIdNews(id).then(rs => {
            if (rs.success) {
                rs.data.forEach(element => {
                    var item: NgxGalleryImage = {
                        small: element.imageUrl,
                        medium: element.imageUrl,
                        big: element.imageUrl
                    };
                    this.galleryImages.push(item);

                });
            }
        })
    }

    onSearchBlog() {
        this._router.navigateByUrl(`/blog?query=${this.query}`);
    }
}
