import { BaseModel } from './basemodel';

export class News extends BaseModel {
    idCategory: number;
    title: string;
    imageUrl: string;
    subject: string;
    contents: string;
    orderNo: number;
    publishedDate: Date;
}
