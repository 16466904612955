import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { NotifierService } from '../../services/notifier.service';
import { OurServiceService } from '../../services/our-service.service';
import { OurTeamService } from '../../services/our-team.service';

@Component({
    selector: 'app-mn-services',
    templateUrl: './mn-services.component.html',
    styleUrls: ['./mn-services.component.scss']
})
export class MnServicesComponent implements OnInit {
    dataOurTeam = [];
    dataOurServices = [];
    formGroup: FormGroup = new FormGroup({});
    phoneNumber = '';
    constructor(
        private _ourTeamService: OurTeamService,
        private _ourServiceService: OurServiceService,
        private _contactService: ContactService,
        private _notifierService: NotifierService,
    ) {
        this.formGroup = new FormGroup({
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        });
    }

    ngOnInit() {
        this.getOurTeam();
        this.getOurServices();
    }

    getOurTeam() {
        this._ourTeamService.get('', 1, 1, 9, 'OrderNo', false).then(rs => {
            if (rs.success) {
                this.dataOurTeam = rs.data;
            }
        })
    }

    getOurServices() {
        this._ourServiceService.get('', 1, 1, 10000, 'OrderNo', false).then(rs => {
            if (rs.success) {
                this.dataOurServices = rs.data;
            }
        })
    }

    addContact() {
        if (this.formGroup.invalid) {
            this._notifierService.showError('Bạn nhập số điện thoại chưa chính xác',)
            return;
        }
        var item = { phoneNumber: this.phoneNumber };
        this._contactService.post(item).then(rs => {
            if (rs.success) {
                this._notifierService.showSuccess('Cảm ơn bạn, chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất!')
            }
        })
    }

}
