import { Component, AfterViewInit, Renderer2, ViewChild, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';


import { Subject } from 'rxjs/internal/Subject';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {

    isAddHeaderBg = false;
    constructor(
    ) {

    }

    ngAfterViewInit() {
        // setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    async ngOnInit() {

    }

    ngOnDestroy(): void {
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

