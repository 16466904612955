import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { NotifierService } from '../../services/notifier.service';


@Component({
    selector: 'app-footer',
    templateUrl: './app-footer.component.html',
    styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent implements OnInit {

    formGroup: FormGroup = new FormGroup({});
    phoneNumber = '';
    constructor(
        private _notifierService: NotifierService,
        private _contactService: ContactService,
    ) {
        this.formGroup = new FormGroup({
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        });
    }

    ngOnInit() {
    }

    addContact() {
        if (this.formGroup.invalid) {
            this._notifierService.showError('Bạn nhập số điện thoại chưa chính xác',)
            return;
        }
        var item = { phoneNumber: this.phoneNumber };
        this._contactService.post(item).then(rs => {
            if (rs.success) {
                this._notifierService.showSuccess('Cảm ơn bạn, chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất!')
            }
        })
    }

}
