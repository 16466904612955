<section class="pager-section mb-0 no-bg style2">
    <div class="container">
        <div class="pager-info">
            <ul class="breadcrumb">
                <li><a routerLink="/" title="">Vườn Mayna</a></li>
                <li><a routerLink="/customers" title="">Hoa trái của vườn</a></li>
                <li><span>{{itemDetail.ten}}</span></li>
            </ul>
            <!--breadcrumb end-->
        </div>
        <div class="clearfix"></div>
    </div>
</section>
<!--pager-section end-->

<section class="page-content pt-0" style="padding-bottom: 40px; margin-top: 30px">
    <div class="container">
        <div class="portfolio-details-page">
            <div class="row">
                <div class="col-lg-12">
                    <div class="portfolio-details-info">
                        <h2>{{itemDetail.ten}}</h2>
                        <p class="blog-detail" [innerHTML]="itemDetail.contents | safe: 'html'"></p>

                        <div class="row">
                            <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
                        </div>

                        <ul class="social-links without-bg">
                        </ul>

                        <ul class="catg-controls">
                            <li *ngIf="navItem.idPrev > 0">
                                <a [routerLink]="['/customers', navItem.idPrev]">
                                    <i class="la la-angle-left"></i>{{navItem.tenPrev}}

                                </a>
                            </li>
                            <li *ngIf="navItem.idNext > 0">
                                <a [routerLink]="['/customers', navItem.idNext]">
                                    {{navItem.tenNext}} <i class="la la-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <!--catalog-product-info end-->
                </div>
            </div>
        </div>
        <!--catalog-product-page end-->
    </div>
</section>
<!--page-content end-->