import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
import { environment } from '../../environments/environment';
import { ResponseResult } from '../models/response-result';


@Injectable({
    providedIn: 'root'
})
export class NewsAttactmentService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.maynaEndpoint}/newsattactment`);
    }

    getsByIdNews(idNews: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsByIdNews/${idNews}`;
        return this.defaultGet(url);
    }

    insertMany(models: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/InsertMany`;
        return this._http
            .post<ResponseResult>(apiUrl, models)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
