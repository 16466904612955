import { Injectable } from '@angular/core';
import { MessageService, ConfirmationService, Message } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class NotifierService {

    constructor(
        private _messageService: MessageService,
        private _confirmService: ConfirmationService,
    ) {

    }

    showSuccess(message: string, header: string = '', life: number = 10000) {
        this._messageService.add({
            key: 'success-warn',
            severity: 'success',
            summary: header,
            detail: message,
            life: life
        });
    }


    showWarning(message: string, header: string = '', life: number = 10000) {
        this._messageService.add({
            key: 'success-warn',
            severity: 'warn',
            summary: header,
            detail: message,
            life: life
        });
    }

    showError(message: string, header: string = '', life: number = 10000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: header,
            detail: message,
            life: life
        });
    }


    showConfirm(message: string, header: string = '', icon: string = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            this._confirmService.confirm({
                message: message,
                header: header,
                icon: icon,
                acceptLabel: 'Đồng ý',
                rejectLabel: 'Hủy',
                accept: () => {
                    resolve();
                },
                reject: () => {
                    reject();
                }
            });
        });
    }


}
