<section class="pager-section">
    <div class="container">
        <div class="pager-info">
            <br>
            <br>
            <br>
            <h3 class="sub-title">Nhật ký trồng vườn</h3>
        </div>
        <div class="pger-imgs style2">
            <div class="abt-imgz">
                <!--img src="assets/images/resources/about3.jpg" alt=""-->
                <img src="assets/images/resources/about4.jpg" alt="">
                <img src="assets/images/resources/about5.jpg" alt="">
                <img src="assets/images/resources/about6.jpg" alt="">
                <img src="assets/images/resources/about7.jpg" alt="">
                <img src="assets/images/resources/about8.jpg" alt="">
                <img src="assets/images/resources/about9.jpg" alt="">
            </div>
        </div>
        <!--pger-imgs end-->
        <div class="clearfix"></div>
    </div>
</section>

<section class="block" style="padding-top: 10px;">
    <div class="container">
        <div class="options">
            <div class="option-isotop text-left">
                <ul id="filter" class="option-set filters-nav" data-option-key="filter">
                    <li *ngFor="let item of dsCategory">
                        <a [class.selected]="idCategory == item.id" [routerLink]="['/blog']"
                            [queryParams]="{catid: item.id}">{{item.ten}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="blog-posts blog-page">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngFor="let item of dataSource">
                    <div class="blog-post">
                        <div class="blog-thumbnail">
                            <img src="{{item.imageUrl}}" alt="">
                            <span class="category">{{item.tenCategory}}</span>
                        </div>
                        <div class="blog-info">
                            <span>{{item.modified | date:'dd/MM/yyyy HH:mm'}}</span>
                            <h2 class="blog-title"><a routerLink="/blog/{{item.id}}" title="">{{item.title}}</a></h2>
                            <p>{{item.subject}}</p>
                            <a routerLink="/blog/{{item.id}}" title="" class="lnk-default2">Chi tiết <i
                                    class="la la-arrow-right"></i></a>
                        </div>
                    </div>
                    <!--blog-post end-->
                </div>
            </div>
        </div>
        <div class="pagination-mint">
            <sv-pagination [pageIndex]="pageIndex" [totalRecord]="totalRecord" [pageSize]="pageSize" [pagesToShow]="5"
                *ngIf="totalRecord > 0" [loading]="isLoading" (onGoPage)="onGoPage($event)" (onPrePage)="onPrePage()"
                (onNextPage)="onNextPage()">
            </sv-pagination>
        </div>
        <!--mint-pagination end-->
    </div>
</section>