<section class="pager-section">
    <div class="container">
        <div class="section-title style2 align-items-center">
            <h3 class="sub-title mw-45">Chúng tôi sẵn sàng cung cấp cho bạn các dịch vụ sau</h3>
            <p class="mw-45">
            </p>
            <div class="clearfix"></div>
        </div>
        <div class="svs-section">
            <div class="svs-item" *ngFor="let item of dataOurServices">
                <div class="svs-img">
                    <img src="{{item.imageUrl}}" alt="" class="w-100">
                </div>
                <div class="svss-info">
                    <h3><a title="">{{item.ten}}</a></h3>
                    <p [innerHTML]="item.contents | safe: 'html'"></p>

                </div>
            </div>

        </div>
        <!--svs-section end-->
    </div>
</section>
<section class="block" style="padding-top: 40px !important;">
    <!--div class="fixed-bg bg1"></div-->
    <div class="container">
        <div class="section-title style2 align-items-center">
            <h3 class="sub-title mw-45">Quy trình triển khai theo tiêu chuẩn Mayna</h3>
            <!--p class="mw-45">Phasellus vel augue lacus. Nunc porttitor mattis elementum. Ut cursus risus quis sem consectetur, fermentum leo him. Pellentesque eget cursus nisi,ornare nisi. Integer orci </p-->
            <div class="clearfix"></div>
        </div>
        <div class="processs-section">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="process-col">
                        <div class="pro-head">
                            <h2>Thiết kế<strong>01.</strong></h2>
                        </div>
                        <ul>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Thiết kế kiến trúc & nội thất </h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Khảo sát khách hàng</li>
                                        <li>Mặt bằng công năng</li>
                                        <li>Ý tưởng trên SketchUp</li>
                                        <li>Ảnh render</li>
                                        <li>Hồ sơ thi công</li>
                                        <li>Thiết lập đơn hàng</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!--process-col end-->
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="process-col">
                        <div class="pro-head">
                            <h2>Kế hoạch<strong>02.</strong></h2>
                        </div>
                        <ul>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Lập kế hoạch</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Khảo sát</li>
                                        <li>Kế hoạch tài chính</li>
                                        <li>Kế hoạch thiết kế</li>
                                        <li>Kế hoạch thi công</li>
                                        <li>Điều chỉnh linh hoạt</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!--process-col end-->
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="process-col">
                        <div class="pro-head">
                            <h2>Tài chính<strong>03.</strong></h2>
                        </div>
                        <ul>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Quản lý khoa học theo tiêu chuẩn của Mayna</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Cập nhật tài chính hàng ngày</li>
                                        <li>Lưu trữ ảnh vật tư & chứng từ liên quan hàng ngày</li>
                                        <li>Tổng kết số dư hàng ngày</li>
                                        <li>Tìm kiếm nhanh chóng dễ dàng</li>
                                        <li>Cảnh báo biến động</li>
                                        <li>Quản lý đơn hàng hiệu quả nhất</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Công khai minh bạch</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Chia sẻ toàn bộ dữ liệu tài chính</li>
                                        <li>Giả đáp mọi thắc mắc tài chính</li>
                                        <li>Đưa giải pháp xử lý sự cố tài chính</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Đàm phán tài chính</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Để lấy chiết khấu tốt nhất</li>
                                        <li>Hoàn 100% chiết khấu về khách hàng</li>
                                        <li>Cùng khách hàng tìm kiếm nhà thầu</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Phí quản lý riêng biệt</h3>
                                        <div class="dott"></div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!--process-col end-->
                </div>
                <!-- Cho xuống dòng 4 và 5 giúp anh nhé-->
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="process-col">
                        <div class="pro-head">
                            <h2>Tiến độ<strong>04.</strong></h2>
                        </div>
                        <ul>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Lập tiến độ tổng thể</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Lập tiến độ trên Project</li>
                                        <li>Cam kết đúng tiến độ</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Hiệu chỉnh tiến độ hàng tuần</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Cập nhật tiến độ</li>
                                        <li>Điều chỉnh tiến độ chi tiết để đảm bảo tiến độ tổng thể</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Báo cáo công việc hàng ngày</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Báo cáo ảnh</li>
                                        <li>Công việc đã làm trong ngày</li>
                                        <li>Dự kiến công việc ngày hôm sau</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!--process-col end-->
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="process-col">
                        <div class="pro-head">
                            <h2>Chất lượng<strong>05.</strong></h2>
                        </div>
                        <ul>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Kiểm tra thường xuyên</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Kiểm tra hàng ngày</li>
                                        <li>Cập nhật bản vẽ hoàn công</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Nghiệm thu theo giai đoạn</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Nghiệm thu theo quy trình</li>
                                        <li>Nghiệm thu bất kỳ</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Kiểm tra định kỳ hàng năm</h3>
                                        <div class="dott"></div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="prc-sorw">
                                    <div class="pz-head">
                                        <h3>Bảo hành dài hạn</h3>
                                        <div class="dott"></div>
                                    </div>
                                    <ul>
                                        <li>Nề bảo hành 12 tháng</li>
                                        <li>Điện nước bảo hành 12 tháng</li>
                                        <li>Điều hòa bảo hành 12 tháng</li>
                                        <li>Chống thấm bảo hành 12 tháng</li>
                                        <li>Thạch cao bảo hành 12 tháng</li>
                                        <li>Sơn bả bảo hành 12 tháng</li>
                                        <li>Hạng mục khác bảo hành theo nhà cung cấp</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!--process-col end-->
                </div>
            </div>
        </div>
        <!--process-section end-->
    </div>
</section>