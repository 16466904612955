import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
import { environment } from '../../environments/environment';
import { ResponseResult } from '../models/response-result';


@Injectable({
    providedIn: 'root'
})
export class CustomerAttactmentService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.maynaEndpoint}/customerattactment`);
    }

    getsByIdCustomer(idCustomer: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsByIdCustomer/${idCustomer}`;
        return this.defaultGet(url);
    }

}
