import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CustomRouterService {

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router
    ) {

    }

    openChildPage(childPageRouter: string, navExtra: NavigationExtras = {}) {
        this._router.navigate([childPageRouter], navExtra);
    }

    openPage(pageRouter: string, navExtra: NavigationExtras = {}) {
        this._router.navigate([pageRouter], navExtra);
    }

    backToParentPage() {
        this._router.navigate(['..'], { relativeTo: this._activatedRoute });
    }

    openCreatePage(navExtra: NavigationExtras = {}) {
        this._router.navigate(['./create'], navExtra);
    }

    openEditPage(id: string, navExtra: NavigationExtras = {}) {
        this._router.navigate([`./edit/${id}`], navExtra);
    }

    parseFragment(fragmentString: string): any {
        if (!fragmentString || fragmentString === '') {
            return {};
        }

        const urlSearchParam = fragmentString.split('&');
        const obj = {};
        for (const index in urlSearchParam) {
            const item = urlSearchParam[index].split('=');
            obj[item[0]] = item[1];
        }
        return obj;
    }

    getFragment(routerUrl: string): string {
        if (routerUrl.indexOf('#') === -1) {
            return '';
        }

        return routerUrl.split('#')[1];
    }

    fragmentToString(fragmentObj: any): string {
        let op = '';
        for (const key in fragmentObj) {
            op += `&${key}=${fragmentObj[key]}`;
        }

        if (op !== '') {
            op = op.substr(1);
        }

        return op;
    }

    appendFragment(fragment: string, key: string, value: any): string {
        const frag = this.parseFragment(fragment);
        frag[key] = value.toString();
        return this.fragmentToString(frag);
    }
    appendFragmentObject(fragment: [], key: string, value: any) {
        fragment[key] = value.toString();
        return fragment;
    }

    removeFragmentObject(fragment: [], key: string) {
        if (fragment[key]) {
            delete fragment[key];
        }
        return fragment;
    }

    removeFragment(fragment: string, key: string): string {
        const frag = this.parseFragment(fragment);

        if (frag[key]) {
            delete frag[key];
        }

        return this.fragmentToString(frag);
    }

    getUrlWithoutParamAndFragment() {

    }

    fragmentIsChildOf(frag1: string, frag2: string): boolean {
        const frag1Obj = this.parseFragment(frag1);
        const frag2Obj = this.parseFragment(frag2);

        for (const ind in frag1Obj) {
            if (frag1Obj[ind] !== frag2Obj[ind]) {
                return false;
            }
        }

        return true;
    }
}
