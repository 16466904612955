import { Component, OnInit } from '@angular/core';
import { FeedbackService } from '../../services/feedback.service';
import { OurTeamService } from '../../services/our-team.service';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    dataOurTeam = [];

    constructor(
        private _ourTeamService: OurTeamService,
    ) { }

    ngOnInit() {
        this.getOurTeam();
    }

    getOurTeam() {
        this._ourTeamService.get('', 1, 1, 99999, 'OrderNo', false).then(rs => {
            if (rs.success) {
                this.dataOurTeam = rs.data;
            }
        })
    }

}
