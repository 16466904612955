import { CustomRouterService } from './custom-router.service';
import { ComponentBase } from './component-base';
import { MenuItem } from 'primeng/api';

import { OnInit, Injector, ViewChild, ElementRef, HostListener, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from './base.service';

@Directive()
export abstract class SecondPageIndexBase extends ComponentBase implements OnInit {

    query = '';
    openSearchAdv = false;
    isCheckAll = false;
    isViewActivity = false;
    isViewSetting = false;
    ids = [];
    totalRecord = 0;
    pageIndex = 1;
    pageSize = 20;
    cols = [];
    isMultiEdit = false;
    dataSource = [];
    dataExport = [];
    openSelectCheck = false;
    orderCol = '';
    isDesc = true;
    isLoading = false;
    selectedItems = [];
    isCollapsed = false;
    isIgnoreClientCache = false;


    openColumnList = false;
    arrTrangThai = [];
    trangThai = 1;
    listItemNumberPerPage = [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
        { label: 'Tất cả' },
    ];


    protected _activatedRoute: ActivatedRoute;
    protected _customRouterService: CustomRouterService;
    protected _router: Router;

    constructor(
        protected _baseService: BaseService,
        protected _injector: Injector
    ) {
        super();
        this._customRouterService = this._injector.get(CustomRouterService);
        this._activatedRoute = this._injector.get(ActivatedRoute);
        this._router = this._injector.get(Router);
    }

    splitExportItems: MenuItem[] = [
        { label: 'Excel', icon: 'fa fa-file-excel' },
        { label: 'Word', icon: 'fa fa-file-word' }
    ];

    ngOnInit(): void {
        this._activatedRoute.fragment
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((fragments) => {
                let query = this._customRouterService.parseFragment(fragments).searchKey;
                if (query === null || query === undefined) {
                    query = '';
                }
                this.query = query;
                this.getData();
            });
        // this.getPopupSize();        
    }


    getData(limit?: number) {
        this.isLoading = true;
        this.selectedItems = [];
        this.ids = [];
        this.resetBulkSelect();
        this._baseService.get(this.query, this.trangThai, this.pageIndex, this.pageSize, this.orderCol, this.isDesc)
            .then(response => {
                this.dataSource = response.data;

                if (response.totalRecord || response.totalRecord === 0) {
                    this.totalRecord = response.totalRecord;
                }

                this.afterGetData();
                this.isLoading = false;
            }, error => {
                this.isLoading = false;
            });
    }




    afterGetData() {

    }

    toggleSearch() {
        this.openSearchAdv = !this.openSearchAdv;
    }

    toggleColumnList() {
        this.openColumnList = !this.openColumnList;
    }

    onSearch(): void {
        this.pageIndex = 1;
        this.getData();
        // this.openSearchAdv = false;
    }

    goToPage(event: any): void {
        this.pageIndex = (event.first / event.rows) + 1;
        this.pageSize = event.rows;
        this.getData();
    }

    onNext(): void {
        this.pageIndex++;
        this.getData();
    }

    onPrev(): void {
        this.pageIndex--;
        this.getData();
    }

    onSort(event: any) {
        this.orderCol = event.field;
        this.isDesc = event.order === 1 ? true : false;
        this.getData();
    }

    onChangeSize(n?: number): void {
        if (this.pageSize === undefined) {
            this.pageSize = this.totalRecord;
        }
        if (n > 0) {
            this.pageSize = n;
        }
        this.pageIndex = 1;
        this.getData();
    }

    viewActivities(): void {
        this.isViewActivity = !this.isViewActivity;
    }

    viewSettings(): void {
        this.isViewSetting = !this.isViewSetting;
    }

    onPage(event: any): void {
        this.pageIndex = (event.first / event.rows) + 1;
        this.pageSize = event.rows;
        this.getData();
    }

    checkItem(item) {
        if (item.checked) {
            this.ids.push(item.id);
            this.selectedItems.push(item);
            item.checked = true;
        } else {
            this.selectedItems.splice(this.selectedItems.indexOf(x => x.id === item.id), 1);
            this.ids.splice(this.ids.indexOf(item.id), 1);
            item.checked = false;
        }
        this.isMultiEdit = this.ids.length > 0 ? true : false;
        this.isCheckAll = this.ids.length === this.dataSource.length ? true : false;
    }

    onCheckAll(ev) {
        if (ev.target.checked) {
            this.isCheckAll = true;
            this.ids = [];
            this.selectedItems = this.dataSource;
            for (let i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].checked = true;
                this.ids.push(this.dataSource[i].id);
            }
        } else {
            this.isCheckAll = false;
            this.ids = [];
            this.selectedItems = [];
            for (let i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].checked = false;
            }
        }
        this.isMultiEdit = this.ids.length > 0 ? true : false;
    }

    onSelectionChanged(event) {
        this.selectedItems = event;
    }

    containSelectItem(item) {
        return this.selectedItems.findIndex(x => x.id === item.id) > -1;
    }

    onPopupClosed(data) {
        this.selectedItems = [];
        if (data) {
            this.getData();
        }
    }

    onPrint() {
        console.log("onPrint");
    }

    onPrintE(element: string) {
    }
    convertDataToOptions(options: any[], data: any[], fieldName: string) {
        if (data.length > 0) {
            data.map(item =>
                options.push({ label: item[fieldName], value: item.id })
            );
        }
    }

    randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    getRandomItemFromList(listItem) {
        let index = Math.floor(Math.random() * listItem.length);

        return listItem[index];
    }

    resetBulkSelect() {
        this.ids = [];
        this.selectedItems = [];
        this.isCheckAll = null;
    }
    onPrePage() {
        if (this.pageIndex > 1) {
            this.pageIndex--;
            this.getData();
        }
    }
    onNextPage() {
        if (this.pageIndex <= this.totalRecord / this.pageSize) {
            this.pageIndex++;
            this.getData();
        }
    }
    onImport() {
        console.log("onImport");
    }
    onCancelSearch() {
        this.openSearchAdv = !this.openSearchAdv;
    }
    add() { };
    edit() { };
    @HostListener('window:keydown.alt.n', ['$event'])
    keyEventAdd(event: KeyboardEvent) {
        console.log(event);
        this.add();
    }
    @HostListener('window:keydown.alt.e', ['$event'])
    keyEventEdit(event: KeyboardEvent) {
        console.log(event);
        this.edit();
    }
    @HostListener('window:keydown.F2', ['$event'])
    keyEventSearch(event: KeyboardEvent) {
        this.openSearchAdv = !this.openSearchAdv;
    }
}
