<footer>
    <div class="container">
        <div class="top-footer">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="widget widget-info">
                        <h3 class="widget-title">Nếu bạn cần tư vấn</h3>
                        <form class="widget-form" [formGroup]="formGroup">
                            <input type="text" name="email" [(ngModel)]="phoneNumber" formControlName="phoneNumber"
                                placeholder="Số điện thoại của bạn">
                            <button type="submit" (click)="addContact()"><i class="la la-arrow-right"></i></button>
                        </form>
                        <span>Chúng tôi sẽ liên hệ tới bạn!</span>
                        <ul class="social-links">
                            <li><a href="https://www.facebook.com/maynaspaces" title="" target="_blank"><i
                                        class="fab fa-facebook-f"></i></a></li>
                        </ul>
                        <!--social-links end-->
                    </div>
                    <!--widget-info end-->
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div class="widget widget-contact text-right">
                                <h3 class="widget-title">Đường thăm vườn</h3>
                                <ul class="ft-links">
                                    <li>Phòng 1006 Tòa G3, <br>
                                        Vinhome GreenBay, <br>
                                        Số 7 Đại lộ Thăng Long</li>
                                    <li>098 785 11 11</li>
                                    <li><a href="mailto:mayna.space@gmail.com" title="">mayna.space@gmail.com</a></li>
                                </ul>
                                <!--ft-links end-->
                            </div>
                            <!--widget-contact end-->
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div class="widget widget-company text-right">
                                <h3 class="widget-title">Liên kết nhanh</h3>
                                <ul class="ft-links">
                                    <li><a routerLink="/about" title="">Sự tích khu vườn</a></li>
                                    <li><a routerLink="/services" title="">Quy trình làm vườn</a></li>
                                    <li><a routerLink="/customers" title="">Hoa trái của vườn</a></li>
                                    <li><a routerLink="/blog" title="">Nhật ký trồng vườn</a></li>
                                    <li><a routerLink="/contact" title="">Đường thăm vườn</a></li>
                                </ul>
                                <!--ft-links end-->
                            </div>
                            <!--widget-contact end-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--top-footer end-->
        <div class="bottom-footer">
            <div class="ft-logo">
                <img src="assets/images/ft-logo.png" alt="">
            </div>
            <!--ft-logo end-->
            <!-- <ul class="btm-links">
                <li><a href="#" title="">Privacy Policy</a></li>
                <li><a href="#" title="">Terms and Conditions</a></li>
            </ul> -->
            <!--btm-links end-->
            <div class="clearfix"></div>
        </div>
        <!--bottom-footer end-->
    </div>
</footer>
<!--footer end-->