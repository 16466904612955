import { QuicklinkModule } from 'ngx-quicklink';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe, } from '@angular/common';


import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppFooterComponent } from './app-content/app-footer/app-footer.component';

import { HomeComponent } from './mayna/home/home.component';
import { AppHeaderComponent } from './app-content/app-header/app-header.component';
import { MaynaContactComponent } from './mayna/contact/mayna-contact.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotifierService } from './services/notifier.service';
import { NewsIndexComponent } from './mayna/news/index/news-index.component';
import { NewsDetailComponent } from './mayna/news/detail/news-detail.component';
import { SvPaginationComponent } from './mayna/paging/pagination.component';
import { SafePipe } from './pipes/safe.pipe';
import { MnServicesComponent } from './mayna/mn-services/mn-services.component';
import { AboutComponent } from './mayna/about/about.component';
import { CustomerIndexComponent } from './mayna/customer/index/customer-index.component';
import { CustomerDetailComponent } from './mayna/customer/detail/customer-detail.component';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { MnFeedbackDetailComponent } from './mayna/mn-feedback-detail/mn-feedback-detail.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutes,
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        QuicklinkModule,
        SlickCarouselModule,
        ConfirmDialogModule,
        ToastModule,
        NgxGalleryModule,
    ],
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        AppFooterComponent,
        HomeComponent,
        AppHeaderComponent,
        MaynaContactComponent,
        NewsIndexComponent,
        NewsDetailComponent,
        SvPaginationComponent,
        SafePipe,
        MnServicesComponent,
        AboutComponent,
        CustomerIndexComponent,
        CustomerDetailComponent,
        MnFeedbackDetailComponent
    ],
    providers: [
        DatePipe,
        ConfirmationService,
        MessageService,
        NotifierService,

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
