import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { catchError } from 'rxjs/operators';
import { BaseService } from './base.service';
import { environment } from '../../environments/environment';
import { ResponseResult } from '../models/response-result';

@Injectable({
    providedIn: 'root'
})
export class CustomerService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.maynaEndpoint}/customer`);
    }

    getByPrevNextId(idCustomer: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetByPrevNextId/${idCustomer}`;
        return this.defaultGet(url);
    }

    find(searchModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(apiUrl, searchModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
