import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { NotifierService } from '../../services/notifier.service';

@Component({
    selector: 'app-mayna-contact',
    templateUrl: './mayna-contact.component.html',
    styleUrls: ['./mayna-contact.component.scss']
})
export class MaynaContactComponent implements OnInit {

    formGroup: FormGroup = new FormGroup({});
    itemDetail = { displayName: '', address: '', email: '', phoneNumber: '', message: '' };
    constructor(
        private _contactService: ContactService,
        private _notifierService: NotifierService,
    ) {
        this.formGroup = new FormGroup({
            displayName: new FormControl(''),
            address: new FormControl(''),
            email: new FormControl(''),
            message: new FormControl(''),
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        });
    }

    ngOnInit() {
    }

    addContact() {
        if (this.formGroup.invalid) {
            this._notifierService.showError('Bạn nhập số điện thoại chưa chính xác',)
            return;
        }
        this._contactService.post(this.itemDetail).then(rs => {
            if (rs.success) {
                this._notifierService.showSuccess('Cảm ơn bạn, chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất!');
                this.itemDetail = { displayName: '', address: '', email: '', phoneNumber: '', message: '' };
            }
        })
    }

}
