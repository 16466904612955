<section class="pager-section no-bg style2">
    <div class="container">
        <div class="pager-info">
            <ul class="breadcrumb">
                <li><a routerLink="/" title="">Vườn Mayna</a></li>
                <li><a routerLink="/blog" title="">Cảm xúc về vườn</a></li>
            </ul>
        </div>
        <!--pger-imgs end-->
        <div class="clearfix"></div>
    </div>
</section>
<!--pager-section end-->

<section class="page-content pt-0" style="padding-bottom: 40px; margin-top: 30px">
    <div class="container">
        <div class="blog-single">
            <div class="row">
                <div class="col-lg-4">
                    <div class="sidebar">
                        <div class="widget popular-posts">
                            <h3 class="widget-title">Cùng chuyên mục</h3>
                            <ul>
                                <li *ngFor="let item of dsFeedback">
                                    <div class="wid-post">
                                        <div class="wid-post-thumb">
                                            <img src="{{item.imageUrl}}" alt="">
                                        </div>
                                        <div class="wid-post-info">
                                            <h3><a routerLink="/feedback/{{item.id}}" title="">{{item.displayName}}</a>
                                            </h3>
                                            <span>{{itemDetail.roleName}}</span>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <!--sidebar end-->
                </div>
                <div class="col-lg-8">
                    <div class="blog-post single">
                        <div class="blog-info">
                            <span>{{itemDetail.modified | date:'dd/MM/yyyy HH:mm'}}</span>
                            <h2 class="blog-title">{{itemDetail.displayName }} - {{itemDetail.roleName}}</h2>
                            <p class="blog-detail" [innerHTML]="itemDetail.contents | safe: 'html'"></p>
                        </div>
                    </div>
                    <!--blog-post single end-->
                </div>
            </div>
        </div>
        <!--blog-single end-->
    </div>
</section>
<!--page-content end-->