<section class="pager-section">
    <div class="container">
        <div class="pager-info">
            <!--ul class="breadcrumb">
                <li><a href="#" title="">Trang chủ</a></li>
                <li><span>Giới thiệu</span></li>
            </ul-->
            <!--breadcrumb end-->
            <h2>Sự tích khu vườn</h2> <!-- Cho màu xanh giống chữ Thiết kế và thi công-->
            <span>Mayna đơn thuần chỉ là từ ghép của bé “Mây” và chị “Nana”,
                hai cô con gái nhỏ của những người sáng lập nên chốn này.
                Khi ấy, họ cùng nghĩ rằng, đây chính là nơi mà những con người có thể rất khác nhau,
                nhưng có thể cảm nhận và hiểu về nhau và chỉ là muốn làm một gì đó nho nhỏ cùng nhau.
                Họ cùng nhau tạo nên khu vườn nhỏ xinh ấy, gieo những hạt mầm non nớt ấy, ngày ngày tưới tắm bằng sự
                nhiệt thành nhất có thể.
                Mong nơi ấy sẽ có thể là chốn dừng chân, nơi ghé thăm của những người bạn cùng một vài góc nhìn trong
                cuộc sống.
                Và giờ đây, vườn thật vui và ấp áp bởi những người bạn ngày qua ngày ghé thăm, nán lại, và gắn bó với
                Mayna. </span>
        </div>
        <div class="row align-items-center">
            <div class="abt-imgz">
                <img src="assets/images/resources/about1.jpg" alt="">
            </div>
        </div>
        <!--pger-imgs end-->
        <div class="clearfix"></div>
    </div>
</section>
<!--pager-section end-->

<section class="block testi-section pb-0" style="padding-top: 40px !important;">
    <div class="container">
        <div class="our-history">
            <h3 class="sub-title">Người gieo hạt</h3> <!-- Text phần này giống phàn trên nhé-->
        </div>
        <!--our-history end-->
        <div class="testimonial-section">
            <div class="row testi-carousel">
                <div class="col-lg-4">
                    <div class="testi-slide">
                        <div class="testi-head">
                            <img src="assets/images/resources/so1.jpg" alt="">
                            <div class="user-info">
                                <h3>Người gieo hạt đầu tiên</h3>
                                <h4>Trái tim nóng</h4>
                            </div>
                        </div>
                        <p>Là một chàng kiến trúc sư chân thành, đa tài, hóm hỉnh, nhiệt huyết….
                            Cũng chính chàng trai ấy tạo dựng nên dấp dáng đầu tiên của Mayna.
                            Cái logo mầu xanh nhẹ mang dáng dấp của bộ ba ngày ấy chính đôi tay chàng vờn vẽ,
                            cái tên ghép Mayna cũng là một phút chợt òa của bao ngày ấp ủ, rồi bao cái vui buồn,
                            được mất dù ít hay nhiêu, dù to hay nhỏ cũng được người ấy ghi chép lại….
                            Vì đứa con tinh thần này mà chàng đổi một quãng lơ là với thơ ca,
                            thiếu nhiệt buổi truyện trò với con gái lớn, con gái nhỏ,
                            lỏng tay mỏi vay khi vào bếp…chàng ưu ái “Mayna” vậy thôi.
                            Ngày Mayna chập chững là chàng nhường bước lùi lại nhìn “con” lớn…gác đấy đã..sau này sao
                            tính tiếp..</p>
                    </div>
                    <!--testi-slide end-->
                </div>
                <div class="col-lg-4">
                    <div class="testi-slide">
                        <div class="testi-head">
                            <img src="assets/images/resources/so2.jpg" alt="">
                            <div class="user-info">
                                <h3>Người gieo hạt thứ tiếp</h3>
                                <h4>Cái đầu lạnh</h4>
                            </div>
                        </div>
                        <p>Cũng là một chàng trai, một kỹ sư CNTT vì nhiều nhân duyên đưa lối để
                            đổi hẳn từ nghề tay
                            phải và cháy hết mình với nghề tay chiêu.
                            Cái đầu lúc nào cũng kế hoạch cũng lập rồi đập là nguyên liệu chính mà chàng trai số 2 đem
                            ra vun đắp cho Mayna hết ngày lại ngày cho đến tận hôm nay.
                            Yêu Mayna nhiều hay ít không ai rõ, chỉ thấy nơi nào có bóng dáng anh em Mayna nơi ấy có
                            bóng dáng chàng,
                            nơi nào có công trình Mayna nơi ấy ấy có bước chân chàng, nơi nào có họp bàn về Mayna nơi ấy
                            có giọng chàng....
                            Cái tinh thần không sợ sai, không ngại sửa, không nản làm lại, luôn luôn gắng đến cái tốt
                            hơn “em” của ngày hôm qua…là gia vị chính mà chàng tạo nên hương vị “Mayna”...</p>
                    </div>
                    <!--testi-slide end-->
                </div>
                <div class="col-lg-4">
                    <div class="testi-slide">
                        <div class="testi-head">
                            <img src="assets/images/resources/so3.jpg" alt="">
                            <div class="user-info">
                                <h3>Người gieo hạt thứ chót</h3>
                                <h4>Sợi dây niềm tin</h4>
                            </div>
                        </div>
                        <p>Không phải là một chàng trai, tiếp nhận và dung hòa những đứa con tinh thần của hai người
                            gieo hạt đầu tiên và người gieo hạt thứ tiếp
                            để cây, để hoa, để trái của Mayna luôn giữ được vị thuần khiết vốn có của nó.
                            Cùng với việc đón nhận và luôn đồng hành đến cùng với những ấp ủ của những chủ nhân
                            để có thể chuyển thể tất cả nhất vào hình hài, vào vóc dáng của những tổ ấm.
                            Và đôi khi được phóng tay một xíu thôi cái vị chua mà mát, đắng mà bổ, cay mà ấm...
                            cho mỗi chốn lại riêng thật là riêng, gần thật là gần...
                            chỉ vậy là đủ để cô nàng mải mê được làm cái thứ mà mình mê lúc nào không biết, mình phải
                            lòng bao lâu không hay.
                            Cái tin, cái yêu nó cứ tự vậy mà bén duyên mà khăng khít...</p>
                    </div>
                    <!--testi-slide end-->
                </div>
            </div>
        </div>
        <!--testimonial-section end-->
    </div>
</section>

<section class="block2" style="padding-top: 40px !important;">
    <div class="container">
        <h3 class="sub-title">Người làm vườn</h3>
        <div class="team-section">
            <div class="row">
                <div class="col-lg-5">
                    <div class="team-head">
                        <h2>{{dataOurTeam.length}} </h2>
                        <h3>Để bạn hiểu về chúng tôi nhiều hơn</h3>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-4 col-12 team-list" *ngFor="let item of dataOurTeam">
                            <div class="team">
                                <img src="{{item.imageUrl}}" alt="">
                                <div class="figcaption">
                                    <h3>{{item.displayName}}</h3>
                                    <span>{{item.roleName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>