import { HomeImgService } from './../../services/home-img.service';
import { CustomerService } from './../../services/customer.service';
import { NewsService } from './../../services/news.service';
import { OurTeamService } from './../../services/our-team.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { Router } from '@angular/router';
import { FeedbackService } from '../../services/feedback.service';
import { ContactService } from '../../services/contact.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotifierService } from '../../services/notifier.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    dataFeedback = [];
    slideConfig = {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    dataCustomer = [];
    customerConfig = {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        centerMode: false,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    dataOurTeam = [];
    formGroup: FormGroup = new FormGroup({});
    phoneNumber = '';
    dataNews = [];

    dataImg = [];
    slickImgConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
    }

    constructor(
        protected _router: Router,
        private _feedbackService: FeedbackService,
        private _ourTeamService: OurTeamService,
        private _contactService: ContactService,
        private _notifierService: NotifierService,
        private _newsService: NewsService,
        private _customerService: CustomerService,
        private _homeImgService: HomeImgService,
    ) {
        this.formGroup = new FormGroup({
            phoneNumber: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
        });
    }

    async ngOnInit() {
        this.getCustomer();
        this.getFeedback();
        // this.getOurTeam();
        this.getNews();
        this.getHomeImg();
        await setTimeout(function () {
            console.log('wait');
        }, 500);
    }

    getFeedback() {
        this._feedbackService.get('', 1, 1, 10, 'Id', false)
            .then(response => {
                this.dataFeedback = response.data;
            }, error => {
            });
    }

    getOurTeam() {
        this._ourTeamService.get('', 1, 1, 9, 'OrderNo', false).then(rs => {
            if (rs.success) {
                this.dataOurTeam = rs.data;
            }
        })
    }

    getNews() {
        var searchModel = {
            keyword: '',
            idCategory: 0,
            trangThai: 1,
            pageIndex: 1,
            pageSize: 3,
            orderCol: 'Id',
            isDesc: true,
        };
        this._newsService.find(searchModel)
            .then(rs => {
                this.dataNews = rs.data;
            });
    }

    addContact() {
        if (this.formGroup.invalid) {
            this._notifierService.showError('Bạn nhập số điện thoại chưa chính xác',)
            return;
        }
        var item = { phoneNumber: this.phoneNumber };
        this._contactService.post(item).then(rs => {
            if (rs.success) {
                this._notifierService.showSuccess('Cảm ơn bạn, chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất!')
            }
        })
    }

    getCustomer() {
        var searchModel = {
            keyword: '',
            idCategory: 0,
            trangThai: 1,
            pageIndex: 1,
            pageSize: 5,
            orderCol: 'id',
            isDesc: true,
        };
        this._customerService.find(searchModel)
            .then(response => {
                this.dataCustomer = response.data;
            });
    }

    getHomeImg() {
        this._homeImgService.getsAll(1).then(rs => {
            if (rs.success) {
                this.dataImg = rs.data;
            }
        })
    }

}
