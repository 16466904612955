<section class="pager-section" style="padding-bottom: 120px !important">
    <div class="container">
        <div class="pager-info">
            <h3 class="sub-title w-100">Hoa trái của vườn</h3>
            <ul class="breadcrumb">
                <div class="project-text">
                    <p>Một góc nhỏ của riêng Mayna, không phải là những kiệt tác, những công trình để đời mà gần như
                        ngược lại,
                        hoa trái của vườn thường là những bức hình dung dị nhất, gần gũi nhất, đời thường nhất của những
                        tổ ấm.
                        Những cảm xúc ghi lại là những câu chuyện mang mầu nhật ký ghi chép,
                        những lưu giữ cảm xúc rất riêng trong cả một chặng dài đồng hành cùng những người bạn. </p>
                </div>
                <!--project-text end-->
            </ul>
        </div>
        <div class="pger-imgs style2">
            <div class="abt-imgz">
                <img src="assets/images/resources/about3.jpg" alt="">
            </div>
        </div>
        <!--pger-imgs end-->
        <div class="clearfix"></div>
    </div>
</section>

<section class="page-content" style="padding-top: 40px !important; padding-bottom: 60px">
    <div class="container">
        <div class="portfolio-page">
            <div class="options">
                <div class="option-isotop text-left">
                    <ul id="filter" class="option-set filters-nav" data-option-key="filter">
                        <li *ngFor="let item of dsCategory">
                            <a [class.selected]="idCategory == item.id" [routerLink]="['/customers']"
                                [queryParams]="{catid: item.id}">{{item.ten}}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-6 architecture" *ngFor="let item of dataSource">
                    <div class="project-item">
                        <img src="{{item.imageUrl}}" alt="">
                        <div class="project-info">
                            <h3><a routerLink="/customers/{{item.id}}" title="">{{item.ten}}</a></h3>
                        </div>
                        <!--project-info end-->
                    </div>
                    <!--project-item end-->
                </div>
            </div>
            <div class="pagination-mint">
                <sv-pagination [pageIndex]="pageIndex" [totalRecord]="totalRecord" [pageSize]="pageSize"
                    [pagesToShow]="5" *ngIf="totalRecord > 0" [loading]="isLoading" (onGoPage)="onGoPage($event)"
                    (onPrePage)="onPrePage()" (onNextPage)="onNextPage()">
                </sv-pagination>
            </div>

        </div>
    </div>
</section>
<!--page-content end-->