import { CustomerService } from './../../../services/customer.service';
import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from '../../../services/second-page-index-base';
import { CategoriesService } from '../../../services/categories.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-customer-index',
    templateUrl: './customer-index.component.html',
    styleUrls: ['./customer-index.component.scss']
})
export class CustomerIndexComponent extends SecondPageIndexBase implements OnInit {
    dsCategory = [];
    idCategory = 0;
    itemDetail: any = { ten: 'Cảm xúc về vườn' };
    constructor(
        protected _customerService: CustomerService,
        protected _injector: Injector,
        protected _categoriesService: CategoriesService,
        private _route: ActivatedRoute,
    ) {
        super(_customerService, _injector);

    }

    async ngOnInit() {
        this.pageSize = 6;
        this.getCategories();
        await this._route.queryParams.subscribe(params => {
            this.idCategory = params['catid'];
            this.query = params['query'];
            this.getData();
        });

        // this.getDetail();
    }

    getCategories() {
        this._categoriesService.getTree(3, true, false).then(rs => {
            if (rs.success) {
                this.dsCategory = rs.data;
            }
        })
    }

    getDetail() {
        if (this.idCategory > 0) {
            this._categoriesService.getDetail(this.idCategory).then(rs => {
                if (rs.success) {
                    this.itemDetail = rs.data;
                }
            })
        }

    }

    async getData() {
        var searchModel = {
            keyword: this.query,
            trangThai: 1,
            idCategory: this.idCategory,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            orderCol: this.orderCol ? this.orderCol : 'Id',
            isDesc: true,
        };
        await this._customerService.find(searchModel)
            .then(response => {
                this.dataSource = response.data;
                this.totalRecord = response.totalRecord;
            }, error => {
                this.isLoading = false;

            });
    }

    onGoPage($event) {
        this.pageIndex = $event;
        this.getData();
    }

}
