import { CustomerService } from './../../../services/customer.service';
import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../../services/component-base';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { CustomerAttactmentService } from '../../../services/customer-attactment.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9';

@Component({
    selector: 'app-customer-detail',
    templateUrl: './customer-detail.component.html',
    styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent extends ComponentBase implements OnInit {
    itemDetail = { id: 0, ten: '', imageUrl: '', contents: '' }
    navItem = { idPrev: 0, idNext: 0 };
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[] = [];
    constructor(
        private _customerService: CustomerService,
        protected _activatedRoute: ActivatedRoute,
        private _customerAttactmentService: CustomerAttactmentService,

    ) {
        super();
        this.galleryOptions = [
            {
                width: '100%',
                height: '1200px',
                thumbnailsColumns: 6,
                imageAnimation: NgxGalleryAnimation.Slide
            },
            // max-width 800
            {
                width: '100%',
                height: '800px',
                breakpoint: 800,
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];


    }

    ngOnInit() {
        this._activatedRoute.params
            .pipe(map(params => params['id']), takeUntil(this._unsubscribeAll))
            .subscribe(pid => {
                this.getDetail(pid);
                this.getAttactment(pid);
            });
    }

    getDetail(id: number) {
        this._customerService.getDetail(id).then(rs => {
            if (rs.success) {
                this.itemDetail = rs.data;
            }
        });

        this._customerService.getByPrevNextId(id).then(rs => {
            if (rs.success) {
                this.navItem = rs.data;
            }
        })
    }

    async getAttactment(id: number) {
        this.galleryImages = [];
        await this._customerAttactmentService.getsByIdCustomer(id).then(rs => {
            if (rs.success) {
                rs.data.forEach(element => {
                    var item: NgxGalleryImage = {
                        small: element.imageUrl,
                        medium: element.imageUrl,
                        big: element.imageUrl
                    };
                    this.galleryImages.push(item);

                });
            }
        })
    }
}
