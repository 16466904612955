<section class="main-banner">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12">
                <div class="banner-slider">
                    <ngx-slick-carousel class="banner-slider" [config]="slickImgConfig">
                        <div ngxSlickItem class="banner-slide" *ngFor="let item of dataImg">
                            <img src="{{item.imageUrl}}" alt="">
                        </div>
                    </ngx-slick-carousel>
                </div>
                <!--banner-slider end-->
            </div>
        </div>
    </div>
</section>
<!--main-banner end-->

<section class="block pb-0">
    <div class="container">
        <div class="about-us-section">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="abt-imgz">
                        <img class="wow fadeInUp" data-wow-duration="1000ms" src="assets/images/resources/abt1.jpg"
                            alt="">
                        <!-- <img class="wow fadeInRight" data-wow-duration="1000ms" data-wow-delay="400ms"
                            src="assets/images/resources/abt2.jpg" alt=""> -->
                    </div>
                    <!--abt-imgz end-->
                    <!-- <div class="abt-txt">
                        <h4>Watch a video about how we work and try for our customers</h4>
                        <span>Play video</span>
                        <a href="https://www.youtube.com/" title="" class="play-btn" target="_blank"><i
                                class="fa fa-play"></i></a>
                    </div> -->
                </div>
                <div class="col-lg-6">
                    <div class="about-text">
                        <h2 class="sub-title">Sự tích khu vườn</h2>
                        <p>Mayna đơn thuần chỉ là từ ghép của bé “Mây” và chị “Nana”, hai cô con gái nhỏ của những người
                            sáng lập nên chốn này. Khi ấy, họ cùng nghĩ rằng, đây chính là nơi mà những con người có thể
                            rất khác nhau, nhưng có thể cảm nhận và hiểu về nhau và chỉ là muốn làm một gì đó nho nhỏ
                            cùng nhau. Họ cùng nhau tạo nên khu vườn nhỏ xinh ấy, gieo những hạt mầm non nớt ấy, ngày
                            ngày tưới tắm bằng sự nhiệt thành nhất có thể. Mong nơi ấy sẽ có thể là chốn dừng chân, nơi
                            ghé thăm của những người bạn cùng một vài góc nhìn trong cuộc sống. Và giờ đây, vườn thật
                            vui và ấp áp bởi những người bạn ngày qua ngày ghé thăm, nán lại, và gắn bó với Mayna.
                        </p>
                        <a routerLink="/about" title="" class="lnk-default2">Xem thêm <i
                                class="la la-arrow-right"></i></a>
                    </div>
                    <!--about-text end-->
                </div>
            </div>
        </div>
        <!--about-us-section end-->
    </div>
</section>
<section class="block">
    <div class="container">
        <div class="section-title align-items-center">
            <h3 class="sub-title">Quy trình làm vườn</h3>
        </div>
        <!--section-title end-->
        <div class="process-section">
            <ul>
                <li>
                    <div class="proz-mint">
                        <h3>Thiết kế</h3>
                        <p>Đề cao công năng <br>
                            Chú trọng thẩm mỹ</p>
                        <h2 class="p-num">01.</h2>
                    </div>
                    <!--proz-mint end-->
                </li>
                <li>
                    <div class="proz-mint">
                        <h3>Tài chính</h3>
                        <p>Minh bạch tài chính <br>
                            Giải pháp tối ưu</p>
                        <h2 class="p-num">03.</h2>
                    </div>
                    <!--proz-mint end-->
                </li>
                <li>
                    <div class="proz-mint">
                        <h3>Chất lượng</h3>
                        <p>Chất lượng là trên hết <br>
                            Bảo hành uy tín</p>
                        <h2 class="p-num">05.</h2>
                    </div>
                    <!--proz-mint end-->
                </li>
                <li>
                    <div class="proz-mint">
                        <h3>Kế hoạch</h3>
                        <p>Kế hoạch chi tiết, đồng bộ <br>
                            Linh hoạt theo thực tế</p>
                        <h2 class="p-num">02.</h2>
                    </div>
                    <!--proz-mint end-->
                </li>
                <li>
                    <div class="proz-mint">
                        <h3>Tiến độ</h3>
                        <p>Điều phối linh hoạt <br>
                            Luôn đảm bảo tiến độ</p>
                        <h2 class="p-num">04.</h2>
                    </div>
                    <!--proz-mint end-->
                </li>
            </ul>
            <div class="clearfix"></div>
        </div>
        <!--process-section end-->
    </div>
</section>

<section class="block2">
    <div class="fixed-bg bg1"></div>
    <div class="container">
        <div class="latest-projects-section">
            <div class="row">
                <div class="col-lg-5">
                    <div class="project-text">
                        <h3 class="sub-title">Hoa trái của vườn</h3>
                        <p>Một góc nhỏ của riêng Mayna,không phải là nững kiệt tác, những công trình để đời mà gần như
                            ngược lại, hoa trái của vườn thường là những bức hình dung dị nhất, gần gũi nhất, đời thường
                            nhất của những tổ ấm. Những cảm xúc ghi lại là những câu chuyện mang mầu nhật ký ghi chép,
                            những lưu giữ cảm xúc rất riêng trong cả một chặng dài đồng hành cùng những người bạn.
                        </p>
                        <a routerLink="/customers" title="" class="lnk-default2">Xem thêm <i
                                class="la la-arrow-right"></i></a>
                    </div>
                    <!--project-text end-->
                </div>
                <div class="col-lg-7">
                    <ngx-slick-carousel class="project-carousel" [config]="customerConfig">
                        <div ngxSlickItem *ngFor="let item of dataCustomer" class="col-lg-4">
                            <div class="project-item">
                                <img src="{{item.imageUrl}}" alt="">
                                <div class="project-info">
                                    <h3><a routerLink="/customers/{{item.id}}" title="">{{item.ten}}</a></h3>
                                </div>
                                <!--project-info end-->
                            </div>
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
        <!--latest-projects-section end-->
    </div>
</section>

<section class="block testi-section">
    <div class="container">
        <div class="section-title">
            <h3 class="sub-title">Cảm xúc về vườn</h3>
        </div>
        <!--section-title end-->
        <div class="testimonial-section">
            <ngx-slick-carousel class="row testi-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let item of dataFeedback" class="col-lg-4">
                    <div class="testi-slide">
                        <div class="testi-head">
                            <img src="{{item.imageUrl}}" alt="">
                            <div class="user-info">
                                <h3>{{item.displayName}}</h3>
                                <span>{{item.roleName}}</span>
                            </div>
                        </div>
                        <p class="testi-slide1-p" [innerHTML]="item.contents | safe: 'html'"></p>
                        <a routerLink="/feedback/{{item.id}}" title="" class="lnk-default2">Chi tiết<i
                                class="la la-arrow-right"></i></a>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
        <!--testimonial-section end-->
    </div>
</section>
<section class="block consultation-sec" style="padding: 0 0 !important;">
    <div class="container">
        <h3 class="sub-title">Nhật ký trồng vườn</h3>
        <div class="blog-posts">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngFor="let item of dataNews">
                    <div class="blog-post">
                        <div class="blog-thumbnail">
                            <img src="{{item.imageUrl}}" alt="">
                            <span class="category">{{item.tenCategory}}</span>
                        </div>
                        <div class="blog-info">
                            <span>{{item.modified | date:'dd/MM/yyyy HH:mm'}}</span>
                            <h2 class="blog-title"><a routerLink="/blog/{{item.id}}" title="">{{item.title}}</a></h2>
                            <p>{{item.subject}}</p>
                            <a routerLink="/blog/{{item.id}}" title="" class="lnk-default2">Chi tiết<i
                                    class="la la-arrow-right"></i></a>
                        </div>
                    </div>
                    <!--blog-post end-->
                </div>

            </div>
        </div>
    </div>
</section>